import './feature.scss'
import 'react-circular-progressbar/dist/styles.css';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { CircularProgressbar } from 'react-circular-progressbar';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const Feature = () => {
  return (
    <div className='feature'>
        <div className="top">
          <h1 className="title">Veniturile totale</h1>
          <MoreVertIcon fontSize='small' />
        </div>
        <div className="bottom">
          <div className="featureChart">
            <CircularProgressbar value={70} text={'70%'} strokeWidth={5} />
          </div>
            <p className="title">
              Vânzări totale pentru astăzi
            </p>
            <p className="amount">
              79,524 Lei
            </p>
            <p className="desc">
              Procesarea tranzacțiilor anterioare, ultima plată poate să nu fie inclusă
            </p>
            <div className="summary">
              <div className="item">
                <div className="itemTitle">Ţintă</div>
                <div className="itemResult negative">
                  <KeyboardArrowDownIcon fontSize='small' />
                  <div className="resultAmount">10.4k</div>
                </div>
              </div>
              <div className="item">
                <div className="itemTitle">Săptămâna trecută</div>
                <div className="itemResult negative">
                  <KeyboardArrowDownIcon fontSize='small' />
                  <div className="resultAmount">12.4k</div>
                </div>
              </div>
              <div className="item">
                <div className="itemTitle">Luna trecuta</div>
                <div className="itemResult positive">
                  <KeyboardArrowUpIcon fontSize='small' />
                  <div className="resultAmount">22.2k</div>
                </div>
              </div>
            </div>
        </div>
    </div>
  )
}

export default Feature