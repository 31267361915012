import './chart.scss'
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';


const data = [
  { name: 'Ianuarie', total: 12000 },
  { name: 'Februarie', total: 30000 },
  { name: 'Martie', total: 10020 },
  { name: 'Aprilie', total: 90000 },
  { name: 'Mai', total: 50000 },
  { name: 'Iunie', total: 32000 },
  { name: 'Iulie', total: 12000 },
 
];

const Chart = () => {
  return (
    <div className='charts'>
      <div className="title">Venituri din ultimele 6 luni</div>
        <ResponsiveContainer width="100%" aspect={2/1}>
        <AreaChart width={'100%'} height={250} data={data} margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
            <defs>
              <linearGradient id="total" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
              </linearGradient>
            </defs>
            <XAxis dataKey="name" />
            <CartesianGrid strokeDasharray="3 3" className='chartGrid' />
            <Tooltip />
            <Area type="monotone" dataKey="total" stroke="#8884d8" fillOpacity={1} fill="url(#total)" />     
        </AreaChart>                 
      </ResponsiveContainer>
    </div>
  )
}

export default Chart