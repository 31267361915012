import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useConfirm } from "material-ui-confirm";
import { DoctorService, alertService } from '../../_services';
import { doctorActions } from '../../_store';
import Header from "../../components/Header";
import { Box, Typography, useTheme } from "@mui/material";
import Addfunctie from './Addfunctie';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
  GridColDef,
  GridRowsProp,
  GridValueGetterParams,
  roRO,
} from '@mui/x-data-grid'; 
import { tokens } from "../../theme";
import { DeleteOutline } from '@mui/icons-material';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { toast } from 'react-toastify';
import {
  MainWrapper,
  ButtonContainer, 
  CloseButton
} from '../../components/styles/CalendarStyles';
import Modal from "react-modal";


function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  );
}

function Functii({ match }) {
  const confirm = useConfirm();
  const theme = useTheme();
  const dispatch = useDispatch();
  const colors = tokens(theme.palette.mode);
  const { path } = useLocation();// match;

  const [functie, setFunctie] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedModal, setSelectedModal] = useState("");
  const customModalStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
    overlay: {zIndex: 1000}
  };
  const openForm = () => {
    setSelectedModal("Addfunctie");
    openModal();
  }

  // Open Modal Function
  const openModal = () => {
    setModalOpen(true);
  };

  // Close Modal Function
  const closeModal = () => {
    setModalOpen(false);
  };

  useEffect(() => {

  DoctorService.getAllposition().then(x => setFunctie(x.data));
  //      dispatch(doctorActions.getAll());
//setDoctor(useSelector(x => x.doctor));
//setProduct(doctor);
  }, [modalOpen]);

// const { doctor } = useSelector(x => x.doctor);
////console.log({doctor});

const onRowsSelectionHandler = (ids) => {
    const selectedRowsData = ids.map((id) => functie.find((row) => row.id === id));
    console.log(selectedRowsData);
  };


 const deleteFunctie = (id) => {
     confirm({title:'Esti Sigur', description: `Se va șterge definitiv functia ${id}.`,confirmationText:'da',cancellationText:'renunta' })
       .then(() => {
          setFunctie(functie.map(x => {
            if (x.id === id) { x.isDeleting = true; }
            return x;
        }));
        DoctorService.removeposition(id).then(() => {
            setFunctie(functie => functie.filter(x => x.id !== id));
        });
      })
      .catch(() => {
       //console.log("Deletion cancelled.")
      });
  };


      const columns = [
    { field: "id", headerName: "Id", hide: true  },
    {
      field: "title",
      headerName: "Titlu",
      width: 300,
      cellClassName: "name-column--cell",
      editable: true,
    },
 
{
      field: 'action',
      headerName: '',
      width: 100,
      renderCell: (params: any) => {
        return (
          <>
    
      <IconButton aria-label="delete"  color="error" onClick={() => deleteFunctie(params.row.id)}>
        <DeleteIcon />
      </IconButton>
          </>
        )
      }
    }
  ];
  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };

    console.log(updatedRow,updatedRow.id);
    DoctorService.updateposition(updatedRow.id, updatedRow)
      .then(() => {
        alertService.success('Update successful', { keepAfterRouteChange: true });
        toast.success("Actualizare cu succes", {
          position: toast.POSITION.TOP_RIGHT
        });
       // navigate("/team");
      })
      .catch(error => {
       // setSubmitting(false);
	//console.log(error.response.data.message);
        toast.error(error, {
          position: toast.POSITION.TOP_RIGHT
        });
        alertService.error(error);
      });
};

const handleProcessRowUpdateError = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };

    console.log(updatedRow);
};
function getStatus(params) {
//console.log(params);
 return `${!params.row.active || 'Activ'} ${params.row.active || 'Inactiv'}`

};

const handleClick = () => {
console.log("aici se adauga");
};

    return (
        <Box m="20px">
        <div>
            <h1>Lista Functii posibile</h1>
            <Button color="secondary" startIcon={<AddIcon />} onClick={openForm}>
        Adauga o functie
      </Button>
           
	            
	     {functie && <Box
        m="8px 0 0 0"
        height="80vh"
	width= "90%"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiChackbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >

	  <DataGrid
               rows={functie}
               columns={columns}
	             pageSize={5}
               rowsPerPageOptions={[5]}
        checkboxSelection
        disableSelectionOnClick
	    editMode="row"
    processRowUpdate={processRowUpdate}
    onProcessRowUpdateError={handleProcessRowUpdateError}
        experimentalFeatures={{ newEditingApi: true }}
	             localeText={roRO.components.MuiDataGrid.defaultProps.localeText} 
               components={{ Toolbar: CustomToolbar }}
	              onSelectionModelChange={(ids) => {
          //console.log(ids);
	  //console.log(ids[0]);
               onRowsSelectionHandler(ids);
	 // //console.log(params);
        }}
        />
      </Box> }
 
        </div>
        {modalOpen && (
          <Modal 
            isOpen={true}
            onRequestClose={closeModal}
            ariaHideApp={false}
            style={customModalStyles}
          >
            <CloseButton onClick={closeModal}>X</CloseButton>
            {
              <Addfunctie closeModal={closeModal}/>
            }
          </Modal>
        )}
        </Box>
    );
}

export { Functii };