import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useParams, useNavigate } from 'react-router-dom';
import { updatePage, deletePage } from "../../_store/pages";
//import PageService from "../services/PageService";
import { PageService } from '../../_services/PageService';
import RichTextEditor from '../../_helpers/RichTextEditor';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "../style.scss";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import slugify from 'react-slugify';
import { Box } from '@mui/material';

const Page = (props) => {
  const { id } = useParams();
  const state = useLocation().state;
  const navigate = useNavigate();
  const [value, setValue] = useState(state?.description || "");
  const initialPageState = {
    id: null,
    title: "",
    description: "",
    published: false
  };
  const [currentPage, setCurrentPage] = useState(initialPageState);
  const [message, setMessage] = useState("");

  const dispatch = useDispatch();

  const getPage = id => {
    PageService.get(id)
      .then(response => {
        setCurrentPage(response.data);
        //setValue(currentPage.description);
        ////window.alert(currentPage.description);
      })
      .catch(e => {
        //console.log(e);
      });
  };

  useEffect(() => {
    if (id)
      getPage(id);
  }, [id]);

const   handleContentChange = contentHtml => {
	setValue(contentHtml);

			    };

  const handleInputChange = event => {
    const { name, value } = event.target;
    setCurrentPage({ ...currentPage, [name]: value });
  };

  const handleEditorChange = (value) => {
    // const { name, value } = event.target;
    setCurrentPage({ ...currentPage, description: value });
  };

  const updateStatus = status => {
    const data = {
      id: currentPage.id,
      title: currentPage.title,
      description: value,
      published: status,
      slug: slugify(currentPage.title)
    };

    dispatch(updatePage({ id: currentPage.id, data }))
      .unwrap()
      .then(response => {
        //console.log(response);
        setCurrentPage({ ...currentPage, published: status });
        setMessage("Starea a fost actualizată cu succes!");
        toast.success("Starea a fost actualizată cu succes!", {
          position: toast.POSITION.TOP_CENTER
        });
      })
      .catch(e => {
		  toast.error(e);
        console.log(e);
      });
  };

  const updateContent = () => {
    // setCurrentPage({ ...currentPage, description: value });
    const data = {
      id: currentPage.id,
      title: currentPage.title,
      description: value,
      published: currentPage.published,
      slug: slugify(currentPage.title)
    };
    ////window.alert(currentPage.description);     dispatch(updatePage({ id: currentPage.id, data: currentPage }))
    dispatch(updatePage({ id: currentPage.id, data }))
      .unwrap()
      .then(response => {
        //console.log(response);
        setMessage("Pagina a fost actualizată cu succes!");
        toast.success("Pagina a fost actualizată cu succes!", {
          position: toast.POSITION.TOP_CENTER
        });
		          navigate("/admin/pages");
      })
      .catch(e => {
        //console.log(e);
		toast.error(e);
      });
  };

  const removePage = () => {
	 if (window.confirm("Stergi pagina? " + currentPage.title)) {
    dispatch(deletePage({ id: currentPage.id }))
      .unwrap()
      .then(() => {
        navigate("/admin/pages");
      })
      .catch(e => {
        //console.log(e);
      });
		  };
  };

  return (
  <Box m="20px">
    <div>
      {currentPage ? (
        <div className="edit-form">
          <h4>Pagina</h4>
          <form>
            <div className="form-group">
              <label htmlFor="title">Titlu</label>
              <input
                type="text"
                className="form-control"
                id="title"
                name="title"
                value={currentPage.title}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="description">Continut</label>
              <input
                type="hidden"
                className="form-control"
                id="description"
                name="description"
                value={value}
                onChange={handleInputChange}
              />

		   <RichTextEditor
            content={value}
            handleContentChange={handleContentChange}
            placeholder="introduceți text aici..."
            />
            </div>
            <div className="form-group">
              <label>
                <strong>Seo:</strong>
              </label>
              {currentPage.slug}
            </div>
            <div className="form-group">
              <label>
                <strong>Status:</strong>
              </label>
              {currentPage.published ? "Publicat" : "Draft"}
            </div>
          </form>

          {currentPage.published ? (
            <button
              className="badge badge-primary mr-2"
              onClick={() => updateStatus(false)}
            >
              Draft
            </button>
          ) : (
            <button
              className="badge badge-primary mr-2"
              onClick={() => updateStatus(true)}
            >
              Publica
            </button>
          )}

          <button className="badge badge-danger mr-2" onClick={removePage}>
            Sterge
          </button>

          <button
            type="submit"
            className="badge badge-success"
            onClick={updateContent}
          >
            Modifica
          </button>
			  <Link to='/admin/pages' className="btn btn-link">Abandon</Link>
          <p>{message}</p>
        </div>
      ) : (
        <div>
          <br />
          <p>Please click on a Page...</p>
        </div>
      )}
    </div>
 </Box>
  );
};

//export default Page;
export { Page };