import { useEffect } from 'react';
import { Box, useTheme } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import Header from "../components/Header";
import { userActions } from '../_store';
import { postActions } from '../_store';
import { Card, CardHeader, CardBody, CardTitle, CardText, CardLink } from 'reactstrap';
import { tokens } from "../theme";
import {Plans} from "./Plans";
export { Home };

function Home() {
 // const theme = useTheme();
 // const smScreen = useMediaQuery(theme.breakpoints.up("sm"));
 // const colors = tokens(theme.palette.mode);
   const theme = useTheme();
  const colors = tokens(theme.palette.mode);
    const dispatch = useDispatch();
    const { user: authUser } = useSelector(x => x.auth);
    const { users } = useSelector(x => x.users);
    //console.log(authUser);
    //console.log(users.length);

    const { posts } = useSelector(x => x.posts);
       // //console.log(posts);
    //console.log(posts.length);
    useEffect(() => {
        dispatch(userActions.getAll());
        dispatch(postActions.getAll());

    }, []);

    return (
            <Box m="20px">
          <Header title="Panou de Control" subtitle="Clinica Dr.Andrei Capra" />
        <div>
 <Card  className="bg-success text-white">
        <CardHeader>
          <CardTitle><h1>Buna {authUser?.firstName} {authUser?.lastName}! </h1></CardTitle>
        </CardHeader>
        <CardBody>
          <CardText>Ești autentificat in CRM ca {authUser?.role}!!! .</CardText>
          <CardText>
            Începti {' '}
            <CardLink
              href='https://dev.andreicapra.ro/'
              target='_blank'
            >
            </CardLink>{' '}
            Asigurați-vă că înțelegeți ce faceți.
          </CardText>
        </CardBody>
</Card>
{authUser?.role === "admin" &&
<Plans state={users}/>
}
            <h4>Ultimile programari:</h4>
	    	                {posts.length &&
                <ul className="list-group ">
                    {posts.map(post =>
                        <li className="list-group-item bg-secondary text-white" key={post.id}>{post.title}</li>
                    )}
                </ul>
            }


        </div>
	        </Box>
    );
}

