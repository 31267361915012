import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useConfirm } from "material-ui-confirm";
import { DoctorService, alertService } from '../../_services';
import { doctorActions } from '../../_store';
import Header from "../../components/Header";
import { Box, Typography, useTheme } from "@mui/material";
//import Addprogram from './Addprogram';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
  GridColDef,
  GridRowsProp,
  GridValueGetterParams,
  roRO,
} from '@mui/x-data-grid'; 
import { tokens } from "../../theme";
import { DeleteOutline } from '@mui/icons-material';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { toast } from 'react-toastify';



function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  );
}
const Program = ({doctorid }) => {
//function Program({ doctorid }) {
  const confirm = useConfirm();
  const theme = useTheme();
  const dispatch = useDispatch();
  const colors = tokens(theme.palette.mode);
  const { path } = useLocation();// match;

  const [program, setProgram] = useState(null);
  const zile = ["Duminca", "Luni", "Marti","Miercuri","Joi","Vineri", "Sambata"];
 

  useEffect(() => {

  DoctorService.getallprogram(doctorid).then(x => setProgram(x.data));
  //      dispatch(doctorActions.getAll());
//setDoctor(useSelector(x => x.doctor));
//setProduct(doctor);
  }, [doctorid]);

// const { doctor } = useSelector(x => x.doctor);
////console.log({doctor});

const onRowsSelectionHandler = (ids) => {
    const selectedRowsData = ids.map((id) => program.find((row) => row.id === id));
    console.log(selectedRowsData);
  };


 /*const deleteprogram = (id) => {
     confirm({title:'Esti Sigur', description: `Se va șterge definitiv programul ${id}.`,confirmationText:'da',cancellationText:'renunta' })
       .then(() => {
          setProgram(program.map(x => {
            if (x.id === id) { x.isDeleting = true; }
            return x;
        }));
        DoctorService.removeposition(id).then(() => {
            setprogram(program => program.filter(x => x.id !== id));
        });
      })
      .catch(() => {
       //console.log("Deletion cancelled.")
      });
  };*/


      const columns = [
    { field: "id", headerName: "Id", hide: true  },
    {
      field: 'zi',
      headerName: 'Zile',
      width: 90,
      renderCell: (params: any) => {
        return (
          <>
{params.row.zi>=0 ? (
                        zile[params.row.zi]
                      ) : (
                        <div className="badge badge-danger">Lipsa</div>
                      )}
          </>
        )
      }
  },
    {
      field: "timeFrom",
      headerName: "Ora de inceput",
      type: 'time',
      width: 100,
      cellClassName: "name-column--cell",
      editable: true,
    },
    {
      field: "timeTo",
      headerName: "Ora de final",
      type: 'time',
      width: 100,
      cellClassName: "name-column--cell",
      editable: true,
    },
    {
      field: "active",
      headerName: "Status",
      type: 'boolean',
      width: 50,
      cellClassName: "name-column--cell",
      editable: true,
    }
 

  ];
  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };

    console.log(updatedRow,updatedRow.id);
    DoctorService.updateprogram(updatedRow.id, updatedRow)
      .then(() => {
        alertService.success('Update successful', { keepAfterRouteChange: true });
        toast.success("Actualizare cu succes", {
          position: toast.POSITION.TOP_RIGHT
        });
       // navigate("/team");
      })
      .catch(error => {
       // setSubmitting(false);
	//console.log(error.response.data.message);
        toast.error(error, {
          position: toast.POSITION.TOP_RIGHT
        });
        alertService.error(error);
      });
};

const handleProcessRowUpdateError = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };

    console.log(updatedRow);
};
function getStatus(params) {
//console.log(params);
 return `${!params.row.active || 'Activ'} ${params.row.active || 'Inactiv'}`

};

const handleClick = () => {
console.log("aici se adauga");
};

    return (
        <Box m="20px">
        <div>
            <h1>Program Saptamanal activ</h1>

           
	            
	     {program && <Box
        m="8px 0 0 0"
        height="80vh"
	width= "90%"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiChackbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >

	  <DataGrid
               rows={program}
               columns={columns}
	             pageSize={7}
               rowsPerPageOptions={[7]}

        disableSelectionOnClick
	    editMode="row"
    processRowUpdate={processRowUpdate}
    onProcessRowUpdateError={handleProcessRowUpdateError}
        experimentalFeatures={{ newEditingApi: true }}
	             localeText={roRO.components.MuiDataGrid.defaultProps.localeText} 
               components={{ Toolbar: CustomToolbar }}
	              onSelectionModelChange={(ids) => {
          //console.log(ids);
	  //console.log(ids[0]);
               onRowsSelectionHandler(ids);
	 // //console.log(params);
        }}
        />
      </Box> }
 
        </div>

        </Box>
    );
}

export default Program;
