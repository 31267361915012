import { fetchWrapper, history } from '../_helpers';
import http from "../http-common";

const getAll = () => {
  return http.get(`/doctor/list`);
};

const get = id => {
  return http.get(`/doctor/id/${id}`);
};

//const getbyslug = slug => {
//  return http.get(`/products/slug/${slug}`);
//};
//const getbyname = name => {
//  return http.get(`/products/name/${name}`);
//};


//const getbyslug = slug => {
//  return http.get(`/products/slug/${slug}`);
//};
//const getbyname = name => {
//  return http.get(`/products/name/${name}`);
//};

const create = data => {
  return http.post(`/doctor`, data);
};

const update = (id, data) => {
	//console.log(id);
	 return http.put(`/doctor/${id}`, data);
 // return http.put("/doctor/${id}", data);
};

const remove = id => {
  return http.delete(`/doctor/${id}`);
};

//const removeAll = () => {
//  return http.delete(`/products`);
//};

//const findByTitle = title => {
//  return http.get(`/products?title=${title}`);
//};
const createposition = data => {
  return http.post(`/doctor/position`, data);
};

const updateposition = (id, data) => {

  return http.put(`/doctor/position/${id}`, data);
};

const removeposition = id => {
  return http.delete(`/doctor/position/${id}`);
};


const getAllposition = () => {
  return http.get(`/doctor/position`);
};

const getposition = id => {
  return http.get(`/doctor/position/${id}`);
};

const getallprogram = id => {
  return http.get(`/doctor/program/${id}`);
};
const updateprogram = (id, data) => {

  return http.put(`/doctor/program/${id}`, data);

};
const DoctorService = {
  getAll,
  get,
  create,
  update,
  remove,
  getAllposition,
  getposition,
  createposition,
  updateposition,
  removeposition,
  getallprogram,
  updateprogram
};

//export default PageService;
export {DoctorService};