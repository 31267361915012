import React, { useEffect, useState  } from 'react';
//import { Link } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import {  Link, useLocation, useNavigate } from "react-router-dom";

import { accountService, alertService } from '../../_services';
 import { toast } from 'react-toastify';
 import { Box } from '@mui/material';
import Header from "../../components/Header";

function WriteUser({  }) {
const state = useLocation().state;
//console.log("ce vine");
//console.log(state);
  const navigate = useNavigate();
   // const { id } = match.params;
 const [id, setId] = useState(state?.id || 0);
 const isAddMode = !id;
const [username, setUsername] = useState(state?.username || '');
const [firstName, setFirstName] = useState(state?.firstName || '');
const [lastName, setLastName] = useState(state?.lastName|| '');
const [email, setEmail] = useState(state?.email|| '');
const [role, setRole] = useState(state?.role|| '');
const [phone, setPhone] = useState(state?.phone|| '');
const [active, setActive] = useState(state?.active|| true);
const [postal_code, setPostal_code] = useState(state?.postal_code|| '');
const [address, setAddress] = useState(state?.address|| '');
    
    //console.log(isAddMode);
    const [status, setStatus] = useState(state?.active || 0);
   const initialValues = {
        username: username,
        firstName: firstName,
        lastName: lastName,
        email: email,
        role: role,
        password: '',
	phone:phone,
        active:active,
        address:address,
	postal_code:postal_code,
        birthDate:null,
        gender:null,
	avatar:'',
        confirmed: false,
        confirmPassword: ''
    }; 
 /*   const initialValues = {
        username: '',
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
        acceptTerms: false
    };*/

		


    const validationSchema = Yup.object().shape({
        username: Yup.string()
            .required('NickName is required'),
        firstName: Yup.string()
            .required('First Name is required'),
        lastName: Yup.string()
            .required('Last Name is required'),
        email: Yup.string()
            .email('Email is invalid')
            .required('Email is required'),
        role: Yup.string()
            .required('Role is required'),
        password: Yup.string()
            .concat(isAddMode ? Yup.string().required('Password is required') : null)
            .min(6, 'Password must be at least 6 characters'),
        confirmPassword: Yup.string()
            .when('password', (password, schema) => {
                if (password) return schema.required('Confirm Password is required');
            })
            .oneOf([Yup.ref('password')], 'Passwords must match')
    });

    function onSubmit(fields, { setStatus, setSubmitting }) {
        setStatus();
        if (isAddMode) {
            createUser(fields, setSubmitting);
        } else {
            updateUser(id, fields, setSubmitting);
        }
    }


    function createUser(fields, setSubmitting) {
        accountService.create(fields)
            .then(() => {
                alertService.success('Utilizatorul adăugat cu succes', { keepAfterRouteChange: true });
				toast.success('Utilizatorul adăugat cu succes');
                navigate("admin/users");
            })
            .catch(error => {
                setSubmitting(false);
                alertService.error(error);
		toast.error(error);
            });
    }

    function updateUser(id, fields, setSubmitting) {
    fields["active"]=status;
   // //window.alert(JSON.stringify({ fields }));
        accountService.update(id, fields)
            .then(() => {
                alertService.success('Actualizare cu succes', { keepAfterRouteChange: true });
		        toast.success("Actualizare cu succes");
                navigate("/admin/users");

            })
            .catch(error => {
                setSubmitting(false);
		      toast.error(error);
                alertService.error(error);
            });
    }



    return (
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
            {({ errors, touched, isSubmitting, setFieldValue }) => {


                return (
                    <Box m="20px">
                    <Form>
                        <h1>{isAddMode ? 'Adăugați utilizator' : 'Editați utilizatorul'}</h1>
                        <div className="form-row">
                            <div className="form-group col">
                                <label>NickName</label>
                                <Field name="username" type="text" className={'form-control' + (errors.username && touched.username ? ' is-invalid' : '')} />
                                <ErrorMessage name="fusername" component="div" className="invalid-feedback" />
                            </div>
                            <div className="form-group col-5">
                                <label>Nume</label>
                                <Field name="firstName" type="text" className={'form-control' + (errors.firstName && touched.firstName ? ' is-invalid' : '')} />
                                <ErrorMessage name="firstName" component="div" className="invalid-feedback" />
                            </div>
                            <div className="form-group col-5">
                                <label>Prenume</label>
                                <Field name="lastName" type="text" className={'form-control' + (errors.lastName && touched.lastName ? ' is-invalid' : '')} />
                                <ErrorMessage name="lastName" component="div" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-7">
                                <label>Email</label>
				{!isAddMode ? ( <>
                                <Field name="email" type="text" readOnly  className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')} />
				</>
				 ) : ( <>
                                <Field name="email" type="text"  className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')} />
                                <ErrorMessage name="email" component="div" className="invalid-feedback" />
				</>
                                )}
                            </div>
                            <div className="form-group col">
                                <label>Telefon</label>
                                <Field name="phone" type="text" className={'form-control' + (errors.phone && touched.phone ? ' is-invalid' : '')} />
                                <ErrorMessage name="phone" component="div" className="invalid-feedback" />
                            </div>
                        </div>
			<div className="form-row">

                            <div className="form-group col-9">
                                <label>Adresa</label>
                                <Field name="address" type="text" className={'form-control' + (errors.address && touched.address ? ' is-invalid' : '')} />
                                <ErrorMessage name="address" component="div" className="invalid-feedback" />
                            </div>
                            <div className="form-group col-3">
                                <label>Cod Postal</label>
                                <Field name="postal_code" type="text" className={'form-control' + (errors.postal_code && touched.postal_code ? ' is-invalid' : '')} />
                                <ErrorMessage name="postal_code" component="div" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="form-row">
                        <div className="form-group col">
                                <label>Rol</label>
                                <Field name="role" as="select" className={'form-control' + (errors.role && touched.role ? ' is-invalid' : '')}>
                                    <option value=""></option>
                                    <option value="user">User</option>
				    <option value="doctor">Doctor</option>
				    <option value="manager">Manager</option>
                                    <option value="admin">Admin</option>
                                </Field>
                                <ErrorMessage name="role" component="div" className="invalid-feedback" />
                            </div>
		      <div className="form-group col">
		 <label>Status {status ? (
                        <div className="badge badge-primary">Activ</div>
                      ) : (
                        <div className="badge badge-danger">Inactiv</div>
                      )}

		        <Field
                          style={{ marginLeft: "1.5em", marginTop: "1.3em" }}
                          type="checkbox"
                          name="active"  onChange={(e) => setStatus(!status)} className={'form-control d-none' +  (errors.active && touched.active ? ' is-invalid' : '')}
                        />
                                <ErrorMessage name="active" component="div" className="invalid-feedback" />

</label>
                       </div>
                        </div>

                        {!isAddMode &&
                            <div>
                                <h3 className="pt-3">Schimbaţi parola</h3>
                                <p>Lăsați necompletat pentru a păstra aceeași parolă</p>
                            </div>
                        }
                        <div className="form-row">
                            <div className="form-group col">
                                <label>Parola</label>
                                <Field name="password" type="password" className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')} />
                                <ErrorMessage name="password" component="div" className="invalid-feedback" />
                            </div>
                            <div className="form-group col">
                                <label>Confirmă parola</label>
                                <Field name="confirmPassword" type="password" className={'form-control' + (errors.confirmPassword && touched.confirmPassword ? ' is-invalid' : '')} />
                                <ErrorMessage name="confirmPassword" component="div" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="form-group">
                            <button type="submit" disabled={isSubmitting} className="btn btn-primary">
                                {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                Salvează
                            </button>
                            <Link to={isAddMode ? '/admin/users' : '/admin/users'} className="btn btn-link">Abandon</Link>
                        </div>
                    </Form>
                    </Box>
                );
            }}
        </Formik>
    );
}

export { WriteUser };