import http from "../http-common";

const getAll = () => {
  return http.get(`/categories`);
};
const getAllwithproducts = () => {
  return http.get(`/categories/all`);
};
const getMain = () => {
  return http.get(`/categories/main`);
};
const get = id => {
  return http.get(`/categories/id/${id}`);
};

const getbyslug = slug => {
  return http.get(`/categories/slug/${slug}`);
};
const getbyname = name => {
  return http.get(`/categories/name/${name}`);
};

const create = data => {
  return http.post(`/categories`, data);
};

const update = (id, data) => {
	//console.log(id);
  return http.put(`/categories/${id}`, data);
};

const remove = id => {
  return http.delete(`/categories/delete/${id}`);
};

const removeAll = () => {
  return http.delete(`/categories`);
};

const findByTitle = title => {
  return http.get(`/categories?title=${title}`);
};

const CategoriesService = {
  getAll,
  getAllwithproducts,
  getMain,
  get,
  create,
  update,
  remove,
  removeAll,
  getbyslug,
  getbyname,
  findByTitle
};

//export default PageService;
export {CategoriesService};