//import Chart from '../../components/chart/Chart'
import Widget from '../components/widget/Widget'
import Feature from '../components/feature/Feature'
//import { userData } from '../../dummyData'
import './home.scss'
export { Plans };
const Plans = () => {
  return (
  <div className="home">
 <div className="homeContainer">

  <div className='widgets'>
            <Widget type='users' />
            <Widget type='orders' />
            <Widget type='earnings' />
            <Widget type='balance' />
          </div>
	            <div className="charts">
            <Feature />
 
          </div>
	  </div>
    </div>
  )
}

//export default Plans