import {useState, useEffect} from 'react';
import {useForm} from "react-hook-form";
import { DoctorService, alertService, accountService } from '../../_services';
import { doctorActions } from '../../_store';
import {useDispatch} from 'react-redux';
//import {add_appointment} from '../redux/appointment/appointmentActions';
//import uuid from 'react-uuid';
import {
    FormWrapper,
    Header,
    ListItem,
    ListContainer,
    AddButton,
    Input,
    Label,
} from '../../components/styles/AppointFormStyles';
	import { toast } from 'react-toastify';

const Addfunctie = ({closeModal}) => {
    // States
  //  const [startDateTime, setStartDateTime] = useState(new Date());
 //   const [endDateTime, setEndDateTime] = useState(new Date());

    //Assign useDispatch hook to a variable
    const dispatch = useDispatch();

    // useForm handler
    const { register, handleSubmit, reset } = useForm();

    // Date Picker State
  //  const [selectedDate, onChangeDate] = useState(new Date());

    // Timerange Picker State
  //  const [selectedTime, onChangeTime] = useState(['10:00', '11:00']);

    // Add start and end date time when state changes
  /*  useEffect(() => {
        let formattedDate = moment(selectedDate).format("YYYY-MM-DD");
        setStartDateTime(formattedDate+"T"+selectedTime[0]+":00");
        setEndDateTime(formattedDate+"T"+selectedTime[1]+":00");
    }, [selectedDate, selectedTime]);*/


    // onSubmit Function
    const onSubmit = (data) => {
        const appointmentInfo = {
            ...data,
        };
			    DoctorService.createposition(appointmentInfo)
      .then(() => {
        toast.success("Functie adăugata cu succes");
        alertService.success('Functie adăugata cu succes', { keepAfterRouteChange: true });

                    reset();
            // Reset date picker and time picker

            // Close modal
            closeModal();
      })
      .catch(error => {
       // setSubmitting(false);
	//console.log(error);
	toast.error(error.response.data.message);

        alertService.error(error);
closeModal();

      });
      
    };

    return (
        <FormWrapper>
            <Header>Creaza Functie Noua</Header>
            <form onSubmit={handleSubmit(onSubmit)}>
                <ListContainer>
                    <ListItem>
                        <Label>Title</Label>
                        <Input
                            name="title"
                            type="text"
                            placeholder="Functie..."
                            required
                            {...register("title", {
                                required: true,
                                maxLength: 255,
                            })}
                        />
                    </ListItem>

                    <ListItem>
                        <AddButton type="submit">Adauga</AddButton>
                    </ListItem>
                </ListContainer>
            </form>
        </FormWrapper>
    );
};

export default Addfunctie;
