import { configureStore } from '@reduxjs/toolkit';

import { authReducer } from './auth.slice';
import { usersReducer } from './users.slice';
import { postsReducer } from './posts.slice';
import { pagesReducer } from './pages.slice';
import { categoriesReducer } from './categories.slice';
import { productsReducer } from './products.slice';
import { doctorReducer } from './doctor.slice';
import { programariReducer } from './programari.slice';
//import PageReducer from './pages.slice';
export * from './pages.slice';
export * from './auth.slice';
export * from './users.slice';
export * from './posts.slice';
export * from './categories.slice';
export * from './products.slice';
export * from './doctor.slice';
export * from './programari.slice';


export const store = configureStore({
    reducer: {
        auth: authReducer,
        users: usersReducer,
	    posts: postsReducer,
		pages: pagesReducer,
		categories: categoriesReducer,
		products: productsReducer,
        doctor: doctorReducer, 
	    programari: programariReducer, 
    },
});