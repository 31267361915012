import http from "../http-common";

const upload = (file, onUploadProgress) => {
  let formData = new FormData();

  formData.append("file", file);

  return http.post("/test/upload", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress,
  });
};

const getFiles = () => {
  return http.get("/test/files");
};

const getAll = () => {
  return http.get(`/doctor/list`);
};

//const delFiles = () => {
//  return http.delete("/api/test/files");
//};

const deleteimg = (name) => {
	    var data = {
      name: name
    };
    return http.delete("/test/files/"+name,data);
  };

const FileUploadService = {
  upload,
  getFiles,
  deleteimg,
};

export default FileUploadService; 
